import axios from 'axios';

const API_URL = 'https://judybackend.applikuapp.com/authentication/';

class AuthService {
  signup(username, password, email, invitationCode) {
    return axios.post(API_URL + 'signup/', {
      username,
      password,
      email,
      invitation_code: invitationCode  // Include the invitation code in the request
    });
  }

  login(username, password) {
    return axios.post(API_URL + 'login/', {
      username,
      password
    }).then(response => {
      if (response.data.token) {
        localStorage.setItem('user', JSON.stringify(response.data)); // Store the entire user data
        return response.data;
      }
    }).catch(error => {
      console.error('Error during login:', error);
      throw error;
    });
  }

  logout() {
    localStorage.removeItem('user'); // Remove the user data from local storage
  }

  getCurrentUser() {
    const user = localStorage.getItem('user');
    return user ? JSON.parse(user) : null; // Return the parsed user data or null if not available
  }

  isAuthenticated() {
    const user = this.getCurrentUser();
    return !!user && !!user.token; // Check if the user and the user's token exist
  }
}

const authService = new AuthService();
export default authService;
