import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai';

const Navbar = () => {
  const [nav, setNav] = useState(false);
  const navigate = useNavigate();

  const handleNav = () => {
    setNav(!nav);
  };

  const isAuthenticated = () => {
    const userData = localStorage.getItem('user');
    return !!userData && !!JSON.parse(userData).token; // Check if userData and its token are present
  };
  
  const handleLogout = () => {
    if (window.confirm('Are you sure you want to log out?')) {
      localStorage.removeItem('user'); // Clear the user data
      navigate('/'); // Navigate to the home page after logout
      window.location.reload(); // Refresh the page
    }
  };

  return (
    <div className='flex justify-between items-center h-20 max-w-[1240px] mx-auto px-4 text-white'>
      {/* Logo on the Left */}
      <Link to="/">
        <h1 className='text-3xl font-bold text-[#ab233a] hover:text-[#811a2c]'>JUDY</h1>
      </Link>

      {/* Navigation Links in the Middle */}
      <ul className='hidden md:flex flex-grow justify-center text-[#2b2d42] text-lg font-semibold '>
        <li className='p-4 hover:text-[#ab233a]'><Link to="/search">Søk</Link></li>
        <li className='p-4 hover:text-[#ab233a]'><Link to="/productinfo">Funksjoner</Link></li>
        <li className='p-4 hover:text-[#ab233a]'><Link to="/contact">Kontakt</Link></li>
      </ul>

      {/* Logg Inn/Ut on the Right */}
      <div className='hidden md:block'>
        {isAuthenticated() ? (
          <button className='py-2 px-3 font-semibold text-lg bg-[#2b2d42] rounded-md text-[#F2F0EB] cursor-pointer whitespace-nowrap hover:bg-[hsl(340,2%,32%)]' onClick={handleLogout}>Logg ut</button>
        ) : (
          <button className='py-2 px-3 font-semibold text-lg bg-[#2b2d42] rounded-md text-[#F2F0EB] cursor-pointer whitespace-nowrap hover:bg-[hsl(340,2%,32%)]' onClick={() => navigate('/login')}>Logg inn</button>
        )}
      </div>

      <div onClick={handleNav} className='block md:hidden'>
        {nav ? <AiOutlineClose size={20} style={{color: '#2b2d42'}}/> : <AiOutlineMenu size={20} style={{color: '#2b2d42'}}/>}
      </div>

      <ul className={nav ? 'text-[#2b2d42] fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#F2F0EB] ease-in-out duration-500' : 'ease-in-out duration-500 fixed left-[-100%] font-semibold'}>
        {/* Mobile Logo with Link to Home */}
        <Link to="/">
          <h1 className='w-full text-3xl font-bold text-[#ab233a] m-4'>JUDY</h1>
        </Link>
        <li className='p-4 border-b border-gray-600 font-semibold'><Link to="/search">Søk</Link></li>
        <li className='p-4 border-b border-gray-600 whitespace-nowrap font-semibold'><Link to="/productinfo">Funksjoner</Link></li>
        <li className='p-4 border-b border-gray-600 font-semibold'><Link to="/contact">Kontakt</Link></li>
        {isAuthenticated() ? (
          <li className='p-4 font-semibold text-[#ab233a] cursor-pointer whitespace-nowrap' onClick={handleLogout}>Logg ut</li>
        ) : (
          <li className='p-4 font-semibold text-[#ab233a] cursor-pointer whitespace-nowrap' onClick={() => navigate('/login')}>Logg inn</li>
        )}
      </ul>
    </div>
  );
};

export default Navbar;
