import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AuthService from '../../services/AuthService';

function SignupComponent() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [invitationCode, setInvitationCode] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      navigate('/');
    }
  }, [navigate]);

  const handleSignup = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setPasswordError("Passwords do not match");
      return;
    } else {
      setPasswordError('');
      setErrorMsg('');
    }

    AuthService.signup(username, password, email, invitationCode).then(
      response => {
        console.log('User signed up:', response.data);
        navigate('/login');
      },
      error => {
        console.log('Signup error:', error.response);
        setErrorMsg("Signup failed: " + (error.response.data.detail || 'An error occurred'));
      }
    );
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen mt-[-100px]">
      <h1 className='md:text-5xl sm:text-4xl text-xl font-bold md:py-6 text-[#ab233a]'>
        Velkommen til JUDY
      </h1>  
      <div className="p-6 bg-white shadow-md rounded-md">
        <h2 className="text-xl font-semibold text-center text-gray-700">Opprett konto</h2>
        <form className="mt-4 space-y-4" onSubmit={handleSignup}>
          <input
            type="text"
            value={invitationCode}
            onChange={e => setInvitationCode(e.target.value)}
            placeholder="Invitasjonskode"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#8d99ae]"
          />
          <input
            type="text"
            value={username}
            onChange={e => setUsername(e.target.value)}
            placeholder="Brukernavn"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#8d99ae]"
          />
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            placeholder="Email"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#8d99ae]"
          />
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            placeholder="Passord"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#8d99ae]"
          />
          <input
            type="password"
            value={confirmPassword}
            onChange={e => setConfirmPassword(e.target.value)}
            placeholder="Bekreft Passord"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#8d99ae]"
          />
          {passwordError && <p className="text-red-500 text-sm mt-1">{passwordError}</p>}
          {errorMsg && <p className="text-red-500 text-sm mt-1">{errorMsg}</p>}
          <button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-[#ab233a] rounded-md hover:bg-[#811a2c] focus:outline-none focus:ring-2 focus:ring-[#ab233a] focus:ring-offset-2"
          >
            Opprett konto
          </button>
        </form>
        <div className="mt-4">
          <p className="text-center">
            Har du allerede en konto?
            <Link to="/login" className="text-[#ab233a] hover:text-[#811a2c] ml-1">Logg inn her!</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignupComponent;
