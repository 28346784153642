import React from 'react';

const ContactPage = () => {
    return (
        <div className="flex flex-col items-center justify-center min-h-screen mt-[-200px]">
            <h1 className="text-3xl font-bold text-center mb-4 text-[#2b2d42">Kontakt oss</h1>
            <div className="max-w-md mx-auto text-center">
                <p className="mb-2 text-[#2b2d42 text-lg">
                    <span className="font-bold text-lg">Email:</span> hei@judy.no
                </p>
                <p>
                    <span className="font-bold text-lg">Phone:</span> +47 941 69 586
                </p>
            </div>
        </div>
    );
};

export default ContactPage;
