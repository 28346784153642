// Importing shared components
import Navbar from './shared/navbar';
import Footer from './shared/footer';

// Importing components specific to Home page
import ContactPage from './ContactComponents/contact';


import React from 'react';  


function ContactInfo() {
    return (
        <div>
            <Navbar />
            <ContactPage/>
            <Footer />
        </div>
    );
}

export default ContactInfo;