import React from 'react';
import { Navigate } from 'react-router-dom';
import AuthService from '../services/AuthService';

function ProtectedRoute({ children }) {
  const isAuthenticated = AuthService.isAuthenticated();

  return isAuthenticated ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;