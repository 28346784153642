import React, { useState, useEffect} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import AuthService from '../../services/AuthService';

function LoginComponent() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();


  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      navigate('/');
    }
  }, [navigate]);


  
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await AuthService.login(username, password);
      if (response && response.token) {
        localStorage.setItem('userToken', response.token);
        navigate('/');
      } else {
        console.log('No token received in response');
      }
    } catch (error) {
      console.error('Login error:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen mt-[-100px]">
      <div className="p-6 bg-white shadow-md rounded-md">
        <h2 className="text-xl font-semibold text-center text-gray-700">Logg inn</h2>
        <form className="mt-4 space-y-4" onSubmit={handleLogin}>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Brukernavn"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#8d99ae]"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Passord"
            className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-[#8d99ae]"
          />
          <button
            type="submit"
            className="w-full px-4 py-2 font-bold text-white bg-[#ab233a] rounded-md hover:bg-[#811a2c] focus:outline-none focus:ring-2 focus:ring-[#ab233a] focus:ring-offset-2"
          >
            Logg inn
          </button>
        </form>
        <div className="mt-4">
          <p className="text-center">
            Har du ikke en bruker? 
            <Link to="/signup" className="text-[#ab233a] hover:text-[#811a2c] ml-1">Opprett en her!</Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default LoginComponent;

