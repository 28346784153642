import React, { useState } from 'react';
import { AiOutlinePlus, AiOutlineMinus } from 'react-icons/ai';

const FAQs = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question: "Kan jeg få tilgang til avgjørelser fra enhver domstol?",
      answer: "For øyeblikket er kun avgjørelser fra Høyesterett tilgjengelig."
    },
    {
      question: "Har dere en mobilversjon?",
      answer: "For tiden er Judy kun tilgjengelig på desktop."
    },
    {
      question: "Bruker Judy GPT-4?",
      answer: "Ja, vi benytter de nyeste KI-modellene, deriblant GPT-4 for noen produktfunksjoner."
    },
    {
      question: "Hvordan håndterer Judy mine data?",
      answer: "Ingen kundedata blir lagret eller brukt til opplæring av modellene."
    },
    
      
    // ... add more FAQs here
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="w-full bg-#F2F0EB py-16 px-4">
      <div className="max-w-[1240px] mx-auto mb-4">
        <h2 className="text-xl font-bold text-[#ab233a]">Ofte stilte spørsmål</h2>
      </div>

      <div className="max-w-[1240px] mx-auto">
        {faqData.map((faq, index) => (
          <div key={index} className={`py-2 rounded-lg border-2 border-grey-300 shadow-lg text-lg bg-white ${activeIndex === index ? 'mb-2' : 'mb-4'}`}>
            <button
              onClick={() => toggleFAQ(index)}
              className="flex items-center justify-between w-full p-4"
            >
              <span>{faq.question}</span>
              {activeIndex === index ? <AiOutlineMinus /> : <AiOutlinePlus />}
            </button>
            <div
              className={`px-4 pb-4 ${activeIndex === index ? 'block' : 'hidden'}`}
            >
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQs;