import React from 'react';
import { useNavigate } from 'react-router-dom';
import Laptop from '../../assets/judy-ai-illu.png';

const Analytics = () => {
  const navigate = useNavigate();

  const goToProductInfo = () => {
    navigate('/productinfo'); // Replace with your actual route
  };

  return (
    <div className='w-full bg-white py-16 px-4'>
      <div className='max-w-[1240px] mx-auto grid md:grid-cols-2'>
        {/* Image on the Left with added margin-right for spacing */}
        <img className='w-[500px] mx-auto my-4 md:mr-20' src={Laptop} alt='Laptop' />

        {/* Text Content on the Right */}
        <div className='flex flex-col justify-center'>
          {/* First Text Section */}
          <div>
            <p className='text-[#ab233a] font-bold'>INTELLIGENT SØK</p>
            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-[#2b2d42]'>
              Finn relevante avgjørelser raskt og enkelt
            </h1>
            <p>
              Ved bruk av kunstig intelligens, forvandler vi måten du søker etter rettsavgjørelser. Ved å analysere og forstå sammenhenger i
              rettsavgjørelser, sikrer vår intelligente søkefunksjon at du raskt og nøyaktig
              finner de mest relevante avgjørelsene.
            </p>
            <button 
              className='bg-[#ab233a] w-[200px] rounded-md font-semibold text-xl my-6 mx-auto py-2 text-[#edf2f4] hover:bg-[#811a2c] shadow-lg'
              onClick={goToProductInfo}
            >
              Les mer
            </button>
          </div>

          {/* Second Text Section */}
          <div className='mt-8'> {/* Add margin-top for spacing between sections */}
            <p className='text-[#ab233a] font-bold'>SMART CHAT</p>
            <h1 className='md:text-4xl sm:text-3xl text-2xl font-bold py-2 text-[#2b2d42]'>
              Chat med rettsavgjørelser
            </h1>
            <p>
              Vår smarte chattefunksjon, lar deg enkelt forstå, tolke og oppsummere rettsavgjørelser,
              slik at du effektivt finner den informasjonen du trenger.
            </p>
            <button 
              className='bg-[#ab233a] w-[200px] rounded-md font-semibold text-xl my-6 mx-auto py-2 text-[#edf2f4] hover:bg-[#811a2c] shadow-lg'
              onClick={goToProductInfo}
            >
              Les mer
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
