import React from 'react';

const Footer = () => {
  return (
    <div className='max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
      <div>
        <h1 className='text-3xl font-bold text-[#ab233a]'>JUDY</h1>
        <p className='text-[#2b2d42]'>Intelligent søk etter rettsavgjørelser.</p>
        <p className='text-[#2b2d42] mt-2'>&copy; 2023 JUDY</p>
      </div>
      
      {/* Links section */}
      <div className='lg:col-span-2 flex justify-end'>
        <div className='flex flex-col md:flex-row items-end'>
          <a href='/privacy-policy' className='text-[#2b2d42] hover:text-[#ab233a] mr-4 mb-2 md:mb-0'>Privacy Policy</a>
          <a href='/terms' className='text-[#2b2d42] hover:text-[#ab233a]'>Terms of Use</a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
