import React, { useState, useEffect, useRef, forwardRef,} from 'react';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import AuthService from '../../services/AuthService';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import { AiOutlineZoomIn, AiOutlineZoomOut, AiOutlineLoading3Quarters } from 'react-icons/ai';
import { IoIosSend } from "react-icons/io";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Popover = forwardRef(({ show, position }, ref) => {
    if (!show) return null;

    return (
        <div ref={ref} style={{ position: 'absolute', top: position.top + 20, left: position.left }} className="z-10 inline-block w-80 text-sm text-gray-700 bg-white border-2 border-gray-200 rounded-lg shadow-sm">
            <div className="px-3 py-2 bg-[#2b2d42] border-b border-gray-200 rounded-t-lg">
                <h3 className="font-semibold text-white">§ 11. Regulering av bruk av sokker og sandaler</h3>
            </div>
            <div className="px-3 py-2">
                <p>Når en person ifører seg sokker i kombinasjon med sandaler, skal sokkene være av en farge som harmonerer med sandalenes primærfarge. Dersom sandalene har flere farger, skal sokkenes farge matche minst en av disse. Overtramp av denne bestemmelsen kan resultere i en symbolsk "mote-misdannelse", som kan straffes med mild latter eller en anbefaling om å skifte fottøy.</p>
            </div>
        </div>
    );
});

const ChatMessage = ({ text, sender }) => {
    const [showPopover, setShowPopover] = useState(false);
    const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 });
    const popoverRef = useRef(null);

    const handleClick = (phrase, event) => {
        setPopoverPosition({ top: event.clientY, left: event.clientX });
        setShowPopover(true);
        // Additional actions based on the phrase can be added here
    };

    const lawPattern = /\b\S*loven\s§{1,2}\s\d+(?:-\d+)?(?:\s(?:første|andre|tredje)?\s?ledd)?(?:\s(?:bokstav)?\s?\w)?/gi;
    const matches = [...text.matchAll(lawPattern)];

    let lastIndex = 0;
    const segments = matches.flatMap(match => {
        const start = match.index;
        const end = start + match[0].length;
        const precedingText = text.slice(lastIndex, start);
        lastIndex = end;
        return [precedingText, match[0]];
    });
    segments.push(text.slice(lastIndex));

    const processedText = segments.map((segment, index) => {
        if (lawPattern.test(segment)) {
            return (
                <span key={index} onClick={(e) => handleClick(segment, e)} style={{ color: '#ab233a', cursor: 'pointer' }}>
                    {segment}
                </span>
            );
        } else {
            return <React.Fragment key={index}>{segment}</React.Fragment>;
        }
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (popoverRef.current && !popoverRef.current.contains(event.target)) {
                setShowPopover(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div className={`p-1 text-black ${sender === 'ai' ? 'bg-[#F2F0EB]' : 'bg-white'} border-b border-gray-300`}>
            <pre className="p-5 px-10 font-noto-sans" style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-word', overflowWrap: 'break-word' }}>
                {processedText}
                {showPopover && <Popover ref={popoverRef} show={showPopover} position={popoverPosition}>
                    {/* Popover content related to the clicked phrase */}
                </Popover>}
            </pre>
        </div>
    );
};


const ChatComponent = () => {
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(1.0);
    const location = useLocation();
    const bottomRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const pdfUrl = location.state?.pdfUrl;

    const scrollToBottom = () => {
        bottomRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [messages]);

    const sendToServer = async (message) => {
        setIsLoading(true);
        try {
            const token = AuthService.getCurrentUser()?.token;
            if (!token) {
                throw new Error('User is not authenticated.');
            }

            const response = await axios.post(
                `https://judybackend.applikuapp.com/api/chat/`, 
                { query: message, pdf_url: pdfUrl },
                { headers: { 'Content-Type': 'application/json', 'Authorization': `Token ${token}` } }
            );
            return response.data.response;
        } catch (error) {
            console.error('There was an error!', error);
            return 'Sorry, there was an error processing your request.';
        } finally {
            setIsLoading(false); // Stop loading regardless of request outcome
        }
    };

    const sendChat = async (event) => {
        event.preventDefault();
        if (!newMessage) return;
    
        const userMessage = newMessage;
        setNewMessage('');
        setMessages(messages => [...messages, { sender: 'user', text: userMessage }]);
    
        const aiResponse = await sendToServer(userMessage);
        setMessages(messages => [...messages, { sender: 'ai', text: aiResponse }]);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const zoomIn = () => {
        setScale(scale + 0.1);
    };

    const zoomOut = () => {
        if (scale > 0.1) setScale(scale - 0.1);
    };

    const adjustTextareaHeight = (event) => {
        event.target.style.height = 'auto'; // Reset height to recalculate
        event.target.style.height = `${event.target.scrollHeight}px`; // Set new height
    };

    const handleTextareaChange = (event) => {
        setNewMessage(event.target.value);
        adjustTextareaHeight(event);
    };


    return (
    <div className="flex h-screen bg-white overflow-hidden border-t border-gray-300">
        <div className="w-1/2 flex flex-col shadow-lg mb-4 border-r border-gray-300">
            <div className="pdf-viewer-controls p-2 px-5 flex justify-between items-center border-b border-grey-300 text-m">
                <span>Antall sider: {numPages}</span>
                <div className="flex justify-end flex-grow">
                    <button onClick={zoomOut} className="zoom-button mx-2">
                        <AiOutlineZoomOut />
                    </button>
                    <button onClick={zoomIn} className="zoom-button mx-2">
                        <AiOutlineZoomIn />
                    </button>
                </div>
            </div>
            <div className="pdf-viewer flex-grow overflow-auto align-content: center  flex justify-center p-5">
                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess} className="pdf-viewer">
                    {Array.from(new Array(numPages), (el, index) => (
                        <Page key={`page_${index + 1}`} pageNumber={index + 1} scale={scale} className="shadow-lg m-2"/>
                    ))}
                </Document>
            </div>
        </div>
        <div className="w-1/2 flex flex-col shadow-lg mb-4 bg-[#F2F0EB]">
            {/* Messages Display */}
            <div className="flex-grow overflow-y-auto">
                {messages.map((message, index) => (
                    <ChatMessage key={index} text={message.text} sender={message.sender} />
                ))}
                <div ref={bottomRef} />
            </div>

            {/* Message Input Form */}
            <form onSubmit={sendChat} className="mb-4 mt-4 mx-4 flex items-start bg-white p-2 rounded-lg border border-gray-300">
                <textarea
                    value={newMessage}
                    onChange={handleTextareaChange}
                    className="flex-grow p-2 mr-2 resize-none overflow-hidden focus:outline-none focus:ring-0 focus:border-transparent"
                    placeholder="Skriv inn din melding (max 1,000 tegn)"
                    maxLength="1000"
                    rows="1"
                    style={{ minHeight: '38px' }}
                ></textarea>
                <button type="submit" className="bg-[#ab233a] hover:bg-[#811a2c] text-white font-bold p-2 rounded-lg flex items-center justify-center">
                    {isLoading ? <AiOutlineLoading3Quarters className="animate-spin" size={20} /> : <IoIosSend size={20} />}
                </button>
            </form>
        </div>
    </div>
    );
};

export default ChatComponent;