import React from 'react';
import { useNavigate } from 'react-router-dom';

const Hero = () => {
  const navigate = useNavigate();

  const goToSearch = () => {
    navigate('/search'); // Replace '/productinfo' with your actual route
  };
  return (
    <div className='text-white'>
      <div className='max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center'>
        <h1 className='md:text-6xl sm:text-5xl text-3xl font-bold md:py-4 text-[#ab233a]'>
          AI-drevet søk etter rettsavgjørelser
        </h1>
        <div className='flex justify-center items-center'>
          <p className='md:text-5xl sm:text-4xl text-xl font-bold py-3 text-[#2b2d42]'>
            Intelligent søk, bedre resultater
          </p>
        </div>
        <p className='md:text-2xl text-xl font-bold text-[#8d99ae]'>Søk etter rettsavgjørelser enklere og mer effektivt.</p>
        <button className='bg-[#ab233a] w-[200px] rounded-md font-semibold text-xl my-6 mx-auto py-2 text-[#edf2f4] hover:bg-[#811a2c] shadow-lg' onClick={goToSearch}>Prøv nå</button>
      </div>
    </div>
  );
};

export default Hero;