import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Search from './components/Search';
import Signup from './components/Signup';
import Login from './components/Login';
import ProductInfo from './components/Productinfo';
import ContactInfo from './components/Contact';
import Chat from './components/Chat';
import ProtectedRoute from './components/ProtectedRoute'; // Import ProtectedRoute


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/search" element={
          <ProtectedRoute>
            <Search />
          </ProtectedRoute>
        } />
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/productinfo" element={<ProductInfo />} />
        <Route path="/contact" element={<ContactInfo />} />
        <Route path="/chat" element={<Chat />} />

      </Routes>
    </Router>
  );
}

export default App;