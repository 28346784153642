import Navbar from './shared/navbar';
import Footer from './shared/footer';

// Importing components specific to Search
import LoginComponent from './AuthComponents/login';

import React from 'react';  


function Login() {
    return (
        <div>
            <Navbar />
            <LoginComponent />
            <Footer />
        </div>
    );
}

export default Login;