// Importing shared components
import Navbar from './shared/navbar';
import Footer from './shared/footer';

// Importing components specific to Home page
import Info from './ProductinfoComponents/productinfo';


import React from 'react';  


function ProductInfo() {
    return (
        <div>
            <Navbar />
            <Info />
            <Footer />
        </div>
    );
}

export default ProductInfo;