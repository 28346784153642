import Navbar from './shared/navbar';
import Footer from './shared/footer';

// Importing components specific to Search
import SignupComponent from './AuthComponents/signup';

import React from 'react';  


function Signup() {
    return (
        <div>
            <Navbar />
            <SignupComponent />
            <Footer />
        </div>
    );
}

export default Signup;