// Importing shared components
import Navbar from './shared/navbar';
import Footer from './shared/footer';

// Importing components specific to Search
import SearchComponent from './SearchComponents/semantic_search';

import React from 'react';  


function Home() {
    return (
        <div>
            <Navbar />
            <SearchComponent />
            <Footer />
        </div>
    );
}

export default Home;