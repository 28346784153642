// Importing shared components
import Navbar from './shared/navbar';

// Importing components specific to chat
import ChatComponent from './ChatComponents/chat';

import React from 'react';  


function Chat() {
    return (
        <div>
            <Navbar />
            <ChatComponent />
        </div>
    );
}

export default Chat;