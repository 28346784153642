import React, { useEffect } from 'react';

const Info = () => {

   useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
      
  return (
    <div className="container mx-auto px-4 py-6">
      <h1 className="text-4xl font-bold text-[#ab233a] mb-1 mt-20 max-w-[900px] mx-auto xt">Søk etter rettsavgjørelser</h1>
      <h2 className="text-xl font-semibold text-[#2b2d42] mb-4 max-w-[900px] mx-auto">Intelligent søk etter rettsavgjørelser drevet av kunstig intelligens. </h2>
      <div className="text-gray-600 text-lg leading-relaxed max-w-[900px] mx-auto">
        <p className="mb-4">
          Å finne relevante rettsavgjørelser kan ofte vise seg å være en utfordrende og tidkrevende prosess. Tradisjonelle søkemotorer kan gi deg enten for mange irrelevante resultater eller ingen i det hele tatt, avhengig av nøyaktigheten av dine søkeord. Søk i Judy tilbyr en mer effektiv og intuitiv måte å søke etter avgjørelser på. I stedet for å basere seg på direkte nøkkelordsøk, bruker Judy kunstig intelligens for å analysere semantikken - eller betydningen - bak søkeforespørselene for å finne relevante rettsavgjørelser som kanskje ikke inneholder de eksakte søkeordene, men som er relevante for brukerens hensik. 
        </p>
        <p className="mb-4">
          Kjernen i Judys effektivitet ligger i anvendelse av naturlig språkbehandling (NLP). Denne teknologien muliggjør en intuitiv forståelse av brukerens intensjoner og den kontekstuelle betydningen bak søkeforespørselen. Ved å akseptere søk i fullstendige setninger eller lengre tekster, fasiliterer Judy en mer naturlig dialog mellom brukeren og informasjonen de søker etter. Dette fører til mer presise og målrettede resultater basert på en dypere forståelse av brukerens spesifikke behov.  
        </p>
      </div>

      <h1 className="text-4xl font-bold text-[#ab233a] mb-1 mt-20 max-w-[900px] mx-auto xt">Chat med rettsavgjørelser</h1>
      <h2 className="text-xl font-semibold text-[#2b2d42] mb-4 max-w-[900px] mx-auto">Dypere forståelse og bedre innsikt ved å chatte med avgjørelser. </h2>
      <div className="text-gray-600 text-lg leading-relaxed max-w-[900px] mx-auto">
        <p className="mb-4">
          Å navigere rettsavgjørelser kan ofte fremstå som en kompleks og utfordrende oppgave. Med Judy’s chatfunksjon, gjøres denne prosessen om til en enkel og interaktiv opplevelse. Judy analyserer rettsavgjørelsen du er interessert i, og leverer presise og direkte svar på dine spørsmål. Enten du ønsker å forstå hvilke lover som er anvendt, hvordan de tolkes i forskjellige sammenhenger, eller bare trenger en oppsummering av en avgjørelse, tilbyr Judy chat en umiddelbar og klar innsikt. 
        </p>
      </div>
    </div>
  );
}

export default Info;
