import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import AuthService from '../../services/AuthService';
import { useNavigate } from 'react-router-dom';
import { AiOutlineLoading3Quarters } from 'react-icons/ai';

function SearchComponent() {
    const [query, setQuery] = useState('');
    const [charCount, setCharCount] = useState(0);
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingPDFs, setLoadingPDFs] = useState({});
    const [error, setError] = useState(null);
    const resultsRef = useRef(null);
    const navigate = useNavigate();

    const handleSearch = async () => {
        setLoading(true);
        setError(null);

        try {
            const token = AuthService.getCurrentUser()?.token;
            if (!token) {
                throw new Error('User is not authenticated.');
            }

            const response = await axios.post(
                'https://judybackend.applikuapp.com/search/api/domsearch/',
                { query },
                {
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            setResults(response.data);
        } catch (error) {
            setError('Error while fetching data.');
            console.error('There was an error!', error);
        }

        setLoading(false);
    };

    const handleTextChange = (e) => {
        setQuery(e.target.value);
        setCharCount(e.target.value.length);
    };

    useEffect(() => {
        if (results.length > 0 && resultsRef.current) {
            resultsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [results]);

    const initiateChatWithPDF = async (pdfUrl) => {
        setLoadingPDFs(prev => ({ ...prev, [pdfUrl]: true })); // Start loading for this PDF
        try {
            const token = AuthService.getCurrentUser()?.token;
            if (!token) {
                throw new Error('User is not authenticated.');
            }

            await axios.post(
                'https://judybackend.applikuapp.com/api/chat/', 
                { pdf_url: pdfUrl },
                {
                    headers: {
                        'Authorization': `Token ${token}`,
                        'Content-Type': 'application/json',
                    },
                }
            );
            navigate('/chat', { state: { pdfUrl } });
        } catch (error) {
            console.error('Error initiating chat:', error);
            setError('Error initiating chat.');
        } finally {
            setLoadingPDFs(prev => ({ ...prev, [pdfUrl]: false })); // End loading for this PDF
        }
    };

  

    return (
        <div className="container mx-auto p-4">
            <div className="max-w-[900px] mt-[-100px] w-full h-screen mx-auto text-center flex flex-col justify-center">
                <h2 className='md:text-4xl sm:text-3xl text-2xl font-bold md:py-1 text-[#ab233a]'>
                    Søk etter rettsavgjørelser
                </h2>
                <div className='flex justify-center items-center'>
                    <p className='text-base py-2 text-[#2b2d42]'>
                        Søk i 3663 avgjørelser fra Høyesterett - Perioden 2005-2023
                    </p>
                </div>
                <textarea
                    value={query}
                    onChange={handleTextChange}
                    placeholder="Skriv inn hva du ønsker å søke etter..."
                    className="shadow border-md rounded w-full py-2 px-3 text-[#2b2d42]"
                    rows="10"
                ></textarea>
                <div className="text-right mr-2 mb-2 text-sm text-[#2b2d42]">
                    {charCount} / 3000 karakterer
                </div>
                <button 
                    onClick={handleSearch} 
                    disabled={loading}
                    className="bg-[#ab233a] w-[200px] rounded-md font-semibold text-xl my-4 mx-auto py-2 text-[#edf2f4] hover:bg-[#811a2c] shadow-lg justify-center" 
                >
                    {loading ? 'Analyserer...' : 'Søk'}
                </button>
            </div>

            {error && <div className="text-red-500">{error}</div>}

            {results.length > 0 && (
                <div className="results mt-6" ref={resultsRef}>
                    <h2 className="text-xl font-bold mt-4 mb-4 text-[#ab233a]">Resultater:</h2>
                    <ul className='divide-y divide-gray-200'>
                        {results.map((item, index) => (
                            <li key={index} className="mb-6 p-4 bg-white shadow-md rounded-lg">
                                <div className="flex justify-between items-center mb-2">
                                    <h3 className="text-lg font-semibold flex-grow text-[#2b2d42]">{item.case.title}</h3>
                                    <span className="text-gray-600 ml-4">Likhet: {item.similarity_score.toFixed(2)}</span>
                                </div>
                                <p className="text-gray-700 text-sm mb-4">{item.case.summary}</p>
                                <button 
                                    onClick={() => initiateChatWithPDF(item.case.pdf_url)}
                                    className="bg-[#2b2d42] text-white font-medium py-1 px-4 rounded-md hover:bg-[hsl(340,2%,32%)] flex items-center justify-center"
                                >
                                    {loadingPDFs[item.case.pdf_url] ? (
                                        <AiOutlineLoading3Quarters className="animate-spin h-5 w-5 mr-2" />
                                    ) : null}
                                    Les og chat med avgjørelsen
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
}

export default SearchComponent;
