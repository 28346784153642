
// Importing shared components
import Navbar from './shared/navbar';
import Footer from './shared/footer';

// Importing components specific to Home page
import Hero from './HomeComponents/hero';
import Analytics from './HomeComponents/analytics'; 
import FAQs from './HomeComponents/faqs';

import React from 'react';  


function Home() {
    return (
        <div>
            <Navbar />
            <Hero />
            <Analytics />
            <FAQs/>
            <Footer />
        </div>
    );
}

export default Home;